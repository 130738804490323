
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap
@import '~bootstrap-icons/font/bootstrap-icons';

// AOS
@import '~aos/dist/aos';


// Gradiente em texto
// background: #FFB339;
// background: -webkit-repeating-linear-gradient(to bottom right,#FFB339 0%,#EC242B 100%);
// background: -moz-repeating-linear-gradient(to bottom right,#FFB339 0%,#EC242B 100%);
// background: repeating-linear-gradient(to bottom right,#FFB339 0%,#EC242B 100%);
//   background-clip: border-box;
// -webkit-background-clip: text;
// -webkit-text-fill-color: transparent;



// Progress -----------------------------------------------------------------------

$primary: #0f97d5;
$secondary: #265eaa;
$green: #b0ca38;
$pink: #E43270;


html, body {
    overflow-x: hidden;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}



// Progress -----------------------------------------------------------------------

.progress {
    background: none !important;
}


// Navbar -----------------------------------------------------------------------

nav.navbar-global .btn-contact {
    color: #fff;
    border-radius: 20px;
    background-color: $green;
    border-color: $green;
}

nav.navbar-global .dropdown-menu  {
    border-radius: 5px;
}

// Alert -----------------------------------------------------------------------

.alert ul {
    margin-bottom: 0;
}


// Pagination -----------------------------------------------------------------------

.page-link, .page-link:hover {
    color: $primary;
    background-color: transparent;
}
.page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
}
.page-item.disabled .page-link {
    background-color: transparent;
}


// Not Found -----------------------------------------------------------------------

section.not-found {
    padding: 60px 0;
}
section.not-found .icon {
    font-size: 30px;
}
section.not-found h2 {
    font-size: 20px;
}



// Item Post -----------------------------------------------------------------------

.item-post {
    border: 1px transparent dotted;
    transition: all 0.2s ease-in-out 0s;
}
.item-post h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 30px;
}
.item-post a {
    text-decoration: none;
    color: #333;
    transition: all 0.2s ease-in-out 0s;
}
.item-post a:hover h3 {
    color: #000;
}
.item-post a h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
}
.item-post.big a h3 {
    font-size: 24px;
    font-weight: 800;
}
.item-post.small a h3 {
    text-align: left;
}
.item-post img {
    width: 100%;
    border-radius: 10px;
    transition: all 0.2s ease-in-out 0s;
}
.item-post .category span {
    background-color: $pink;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    margin: auto;
    transition: all 0.2s ease-in-out 0s;
}
.item-post.small .category span {
    font-size: 12px;
}
.item-post:hover {
    border: 1px #ccc dotted;
    border-radius: 10px;
}
.item-post:hover .category span {
    padding: 5px 15px;
}
.item-post a:hover img {
    opacity: 0.8;
}




// Footer -----------------------------------------------------------------------

footer.footer .others {
    margin: 40px 0;
}
footer.footer .others .social {
    text-align: center;
}
footer.footer .others .social i {
    font-size: 24px;
}
footer.footer .others .social a {
    color: $primary;
    transition: all 0.2s ease-in-out 0s;
}
footer.footer .others .social a:hover {
    color: $secondary;
}
footer.footer .others .address {
    text-align: right;
}
footer.footer .lgpd {
    margin: 40px 0;
}


// Copyright -----------------------------------------------------------------------

footer.copyright {
    color: #333;
}



// Whatsapp -----------------------------------------------------------------------

.btn-whatsapp {
    position: fixed;
    bottom: 95px;
    right: 15px;
}
.btn-whatsapp img {
    border-radius: 60px;
    width: 50px;
    transition: all 0.2s ease-in-out 0s;
}
.btn-whatsapp img:hover {
    width: 60px;
}





// Mobile 

@media (max-width: 576px) {


    // Header -----------------------------------------------------------------------

    .navbar .navbar-brand img {
        max-width: 150px;
    }


    // Blog -----------------------------------------------------------------------

    .item-post.small a h3 {
        text-align: center;
    }
    .item-post.small .category {
        text-align: center;
    }


    // Footer -----------------------------------------------------------------------

    footer.footer .others .time, footer.footer .others .address {
        text-align: center;
    }
    footer.footer .others .address {
        padding-top: 15px;
    }
    footer.footer .lgpd {
        text-align: center;
    }

}
// Bootstrap

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Arial', sans-serif;
$font-size-base: 0.8rem;
$line-height-base: 1.6;

// Badges
$badge-padding-y: .35em;
$badge-padding-x: 1.05em;
$badge-font-weight: normal;

// Border
$border-radius:               0 !default;
$border-radius-sm:            0 !default;
$border-radius-lg:            0 !default;
$border-radius-pill:          50rem !default;


$theme-colors: (
  "primary":    #0d6efd,
  "secondary":  #9D9E9E,
  "success":    #84b33d,
  "info":       #0dcaf0,
  "warning":    #ffc107,
  "danger":     #d62828,
  "light":      #e6e9ed,
  "dark":       #334940
);


